import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, error }) => ({
    '& .wrap': {
        position: 'relative'
    },

    '& select': {
        display: 'block',
        fontSize: toRem(14),
        color: error ? '#D32929' : theme.colors.white,
        border: 'none',
        padding: '10px 25px 0 25px',
        height: 60,
        width: '100%',
        boxSizing: 'borderBox',
        margin: 0,
        borderRadius: 4,
        appearance: 'none',
        background: theme.colors.blueLight,
        boxShadow: error ? `0 0 0 2px #D32929` : '',

        '&:focus': {
            outline: 'none',
            boxShadow: `0 0 0 2px ${theme.colors.goldDark}`
        },

        [theme.breakpoints.up('lg')]: {
            fontSize: toRem(16),
            padding: '20px 25px 0 25px',
            height: 80
        }
    },

    '& select::-ms-expand': {
        display: 'none'
    },

    '& label': {
        position: 'absolute',
        top: '50%',
        left: 25,
        transform: 'translateY(-50%)',
        fontSize: toRem(12),
        fontWeight: theme.typography.fontWeight.medium,
        color: error ? '#D32929' : theme.colors.white,
        transition: 'all 0.5s ease',

        [theme.breakpoints.up('lg')]: {
            fontSize: toRem(14)
        },

        '&.isActive': {
            color: error ? '#D32929' : theme.colors.goldDark,
            top: 10,
            transform: 'translateY(0)',

            [theme.breakpoints.up('lg')]: {
                top: 17
            }
        }
    },

    '& .arrow': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        marginTop: -2,
        right: 25,
        width: 10,
        height: 10,
        lineHeight: 0,

        path: {
            stroke: error ? '#D32929' : theme.colors.white,
            strokeWidth: 2
        }
    },

    '& .inputError': {
        fontSize: toRem(10),
        color: '#D32929',
        fontStyle: 'italic',
        paddingLeft: toRem(20),
        paddingTop: toRem(5),

        '&:before': {
            content: '"*"'
        }
    }
})

export default style
