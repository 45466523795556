import { withTheme } from 'emotion-theming'
import { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useUIDSeed } from 'react-uid'

import ArrowIcon from 'public/icons/arrow.svg'

import style from './Select.style'

const SelectStyled = styled.div(props => ({ ...style(props) }))

const Select = ({ label, id, name, options, value, error, required, onChange, onBlur, disabled }) => {
    const [active, setActive] = useState(value.length > 0)

    const handleBlur = e => {
        onBlur(name, true)
        setActive(e.target.value.length > 0)
    }

    const uid = useUIDSeed()

    return (
        <SelectStyled error={error}>
            <div className="wrap">
                <label htmlFor={id} className={active ? 'isActive' : ''}>
                    {label}
                </label>
                <select
                    id={id}
                    name={name}
                    onChange={onChange}
                    onBlur={e => handleBlur(e)}
                    onFocus={() => setActive(true)}
                    value={value}
                    disabled={disabled}
                    required={required}>
                    {options.map(item => (
                        <option key={uid(item)} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>

                <div className="arrow">
                    <ArrowIcon />
                </div>
            </div>

            {error && (
                <div className="inputError" role="alert">
                    {error}
                </div>
            )}
        </SelectStyled>
    )
}

Select.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string
        })
    ).isRequired,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
}

Select.defaultProps = {
    value: undefined,
    error: null,
    required: false,
    disabled: false,
    onChange: () => {},
    onBlur: () => {}
}

export default withTheme(Select)
