import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, error }) => ({
    '.wrap': {
        position: 'relative'
    },

    textarea: {
        width: '100%',
        padding: '28px 25px 15px 25px',
        background: error
            ? `${theme.colors.blueLight} url(icons/error.svg) no-repeat right 20px center`
            : theme.colors.blueLight,
        border: 'none',
        borderRadius: 4,
        fontSize: toRem(14),
        minHeight: 160,
        color: error ? '#D32929' : theme.colors.white,
        resize: 'vertical',
        fontFamily: 'Poppins',

        '&:focus': {
            outline: 'none',
            boxShadow: `0 0 0 2px ${theme.colors.goldDark}`
        },

        '&:disabled': {
            resize: 'none'
        },

        boxShadow: error ? `0 0 0 2px #D32929` : '',

        [theme.breakpoints.up('lg')]: {
            fontSize: toRem(16),
            padding: '40px 25px 26px 25px',
            minHeight: 300
        }
    },

    label: {
        position: 'absolute',
        top: 22,
        left: 25,
        fontSize: toRem(12),
        fontWeight: theme.typography.fontWeight.medium,
        color: error ? '#D32929' : theme.colors.white,
        transition: 'all 0.5s ease',

        [theme.breakpoints.up('lg')]: {
            fontSize: toRem(14)
        },

        '&.isActive': {
            color: error ? '#D32929' : theme.colors.goldDark,
            top: 10,
            transform: 'translateY(0)',

            [theme.breakpoints.up('lg')]: {
                top: 17
            }
        }
    },

    '& .inputError': {
        fontSize: toRem(10),
        color: '#D32929',
        fontStyle: 'italic',
        paddingLeft: toRem(20),

        '&:before': {
            content: '"*"'
        }
    }
})

export default style
