import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, onPage }) => ({
    '.merkForm': {
        marginBottom: 80,
        maxWidth: 1400,

        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
        },

        [theme.breakpoints.up('xl')]: {
            justifyContent: 'flex-start'
        }
    },
    '.col-1': {
        [theme.breakpoints.up('md')]: {
            width: '49%'
        },

        [theme.breakpoints.up('lg')]: {
            width: '48%'
        },

        [theme.breakpoints.up('xl')]: {
            width: '33%',
            marginRight: toRem(40)
        }
    },

    '.col-2': {
        [theme.breakpoints.up('md')]: {
            width: '49%'
        },

        [theme.breakpoints.up('lg')]: {
            width: '48%'
        },

        [theme.breakpoints.up('xl')]: {
            width: '33%'
        }
    },

    '& button.primary': {
        height: 60,

        '&.submitting span': {
            width: 50
        },

        [theme.breakpoints.up('lg')]: {
            height: 80
        }
    },

    '& .QuickTip': {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block',
            maxWidth: 280,
            marginLeft: '5rem',
            background: theme.colors.goldDark,
            padding: toRem(40),
            color: theme.colors.blueMid,
            fontSize: toRem(14),
            borderRadius: 4,

            '&__head': {
                display: 'flex',
                alignItems: 'center',
                marginBottom: toRem(20)
            },

            '.Title': {
                marginLeft: 20
            },

            '.CloseButton': {
                marginLeft: 'auto',
                cursor: 'pointer',

                'svg path': {
                    fill: theme.colors.blueMid
                }
            },

            p: {
                fontSize: toRem(14),
                marginBottom: 20
            }
        },

        [theme.breakpoints.up('xxl')]: {
            maxWidth: 360
        }
    },

    '& .thank-you-message': {
        padding: onPage ? 0 : toRems([0, 20]),

        [theme.breakpoints.up('1700')]: {
            padding: onPage ? 0 : toRems([0, 5])
        }
    }
})

export default style
