import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import CheckmarkIcon from 'public/icons/checkmark.svg'

import style from './Checkbox.style'

const CheckboxStyled = styled.div(props => ({ ...style(props) }))

const Checkbox = ({ id, value, name, label, checked, error, required, onChange, onBlur, disabled }) => {
    return (
        <CheckboxStyled error={error}>
            <label htmlFor={id}>
                <input
                    name={name}
                    type="checkbox"
                    value={value}
                    id={id}
                    checked={checked}
                    required={required}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
                />
                <div className="checkmark">
                    <CheckmarkIcon />
                </div>
                {/* eslint-disable react/no-danger */}
                <span className="inputLabel">{label}</span>
            </label>

            {error && (
                <div className="inputError" role="alert">
                    {error}
                </div>
            )}
        </CheckboxStyled>
    )
}

Checkbox.propTypes = {
    value: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    checked: PropTypes.bool,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
}

Checkbox.defaultProps = {
    value: undefined,
    checked: null,
    error: undefined,
    required: false,
    disabled: false,
    onChange: () => {},
    onBlur: () => {},
}

export default withTheme(Checkbox)
