import { withTheme } from 'emotion-theming'
import { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import style from './Input.style'

const InputStyled = styled.div(props => ({ ...style(props) }))

const Input = ({ type, label, name, id, value, error, required, onChange, onBlur, disabled }) => {
    const [active, setActive] = useState(!!error)

    const handleBlur = e => {
        onBlur(name, true)
        setActive(e.target.value.length > 0)
    }

    return (
        <InputStyled error={error}>
            <div className="wrap">
                <label htmlFor={id} className={active ? 'isActive' : ''}>
                    {label}
                </label>
                <input
                    id={id}
                    type={type}
                    name={name}
                    onChange={onChange}
                    onBlur={e => handleBlur(e)}
                    onFocus={() => setActive(true)}
                    value={value}
                    disabled={disabled}
                    required={required}
                />
            </div>

            {error && (
                <div className="inputError" role="alert">
                    {error}
                </div>
            )}
        </InputStyled>
    )
}

Input.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
}

Input.defaultProps = {
    type: 'text',
    value: undefined,
    error: null,
    required: false,
    disabled: false,
    onChange: () => {},
    onBlur: () => {}
}

export default withTheme(Input)
