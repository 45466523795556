import { useState } from 'react'
import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation, Link } from 'server/i18n'
import { Flex, Box } from 'reflexbox'
import { Formik } from 'formik'
import { useRouter } from 'next/router'

import { submitLeadForm } from 'helpers/middleware'

import Input from 'components/Input'
import Textarea from 'components/Textarea'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import Select from 'components/Select'
import QuestionIcon from 'public/icons/question.svg'
import CloseIcon from 'public/icons/close.svg'
import InquryLoader from 'public/icons/inquiry-loader.svg'

import layoutTheme from 'theme/layout-theme'
import style from './InquiryForm.style'

const InquiryFormStyled = styled.div(props => ({ ...style(props) }))

const theme = {
    breakpoints: ['360px', '728px', '1024px', '1280px', '1600px']
}

const InquiryForm = ({ t, type, onPage }) => {
    const [submitted, setSubmitted] = useState(false)
    const [quickTip, setQuickTip] = useState(true)
    const router = useRouter()

    const closeQuickTip = () => {
        setQuickTip(false)
    }

    const errorRequired = t('controls:error-required-field')
    const errorEmail = t('controls:error-invalid-email')

    const options = [
        { value: '', label: '' },
        { value: 'buy', label: t('buying-yacht') },
        { value: 'sell', label: t('selling-yacht') },
        { value: 'manage', label: t('manage-yacht') }
    ]

    if (submitted) {
        return (
            <InquiryFormStyled onPage={onPage}>
                <div className="thank-you-message">
                    <p>{t('thank-you-message')}</p>
                </div>
            </InquiryFormStyled>
        )
    }

    return (
        <InquiryFormStyled>
            <Formik
                initialValues={{
                    inquiryType: type || '',
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    phoneNumber: '',
                    message: '',
                    privacyPolicy: false,
                    boatUrl: router.query.brochure ? `BROCHURE for ${router.query.boatID}` : router?.query?.boatID
                }}
                validate={values => {
                    const errors = {}
                    if (!values.inquiryType) {
                        errors.inquiryType = errorRequired
                    }
                    if (!values.firstName) {
                        errors.firstName = errorRequired
                    }
                    if (!values.lastName) {
                        errors.lastName = errorRequired
                    }
                    if (!values.emailAddress) {
                        errors.emailAddress = errorRequired
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)) {
                        errors.emailAddress = errorEmail
                    }
                    if (!values.phoneNumber) {
                        errors.phoneNumber = errorRequired
                    }
                    if (!values.message) {
                        errors.message = errorRequired
                    }
                    if (!values.privacyPolicy) {
                        errors.privacyPolicy = errorRequired
                    }
                    return errors
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    const response = await submitLeadForm(values)
                    if (Object.entries(response || {}).length) {
                        setSubmitting(false)
                        setSubmitted(true)
                    }

                    if (process.browser)
                        window.dataLayer.push({
                            event: 'GAEvent',
                            eventCategory: 'Form Submit',
                            eventAction: 'Contact',
                            eventLabel: 'Send message'
                        })
                }}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldTouched
                }) => (
                    <form className="merkForm" onSubmit={handleSubmit} noValidate>
                        <div className="col-1">
                            <Box theme={theme} mb="1.25rem">
                                <Select
                                    id="inquiryType"
                                    label={t('inquiry-type')}
                                    name="inquiryType"
                                    onChange={handleChange}
                                    onBlur={setFieldTouched}
                                    value={values.inquiryType}
                                    options={options}
                                    required
                                    error={errors.inquiryType && touched.inquiryType && errors.inquiryType}
                                />
                            </Box>

                            <Flex theme={theme} mb="1.25rem" justifyContent="space-between">
                                <Box theme={theme} width="48%">
                                    <Input
                                        id="firstName"
                                        label={t('first-name')}
                                        type="text"
                                        name="firstName"
                                        onChange={handleChange}
                                        onBlur={setFieldTouched}
                                        value={values.firstName}
                                        required
                                        error={errors.firstName && touched.firstName && errors.firstName}
                                    />
                                </Box>

                                <Box theme={theme} width="48%">
                                    <Input
                                        id="lastName"
                                        label={t('last-name')}
                                        type="text"
                                        name="lastName"
                                        onChange={handleChange}
                                        onBlur={setFieldTouched}
                                        value={values.lastName}
                                        required
                                        error={errors.lastName && touched.lastName && errors.lastName}
                                    />
                                </Box>
                            </Flex>

                            <Box theme={theme} mb="1.25rem">
                                <Input
                                    id="emailAddress"
                                    label={t('email-address')}
                                    type="email"
                                    name="emailAddress"
                                    onChange={handleChange}
                                    onBlur={setFieldTouched}
                                    value={values.emailAddress}
                                    required
                                    error={errors.emailAddress && touched.emailAddress && errors.emailAddress}
                                />
                            </Box>

                            <Box theme={theme} mb="1.25rem">
                                <Input
                                    id="phoneNumber"
                                    label={t('phone-number')}
                                    type="text"
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    onBlur={setFieldTouched}
                                    value={values.phoneNumber}
                                    required
                                    error={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                                />
                            </Box>
                        </div>
                        <div className="col-2">
                            <Box theme={theme} mb="1.25rem">
                                <Textarea
                                    id="message"
                                    label={t('message')}
                                    name="message"
                                    onChange={handleChange}
                                    onBlur={setFieldTouched}
                                    required
                                    error={errors.message && touched.message && errors.message}
                                />
                            </Box>

                            <Box theme={theme} mb="1.25rem">
                                <Checkbox
                                    id="privacyPolicy"
                                    label={
                                        <>
                                            {t('privacy-policy-note')}{' '}
                                            <Link href="/privacy-policy">
                                                <a>{t('privacy-policy-link')}</a>
                                            </Link>
                                            {t('privacy-policy-rest')}
                                        </>
                                    }
                                    name="privacyPolicy"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.privacyPolicy}
                                    checked={values.privacyPolicy}
                                    required
                                    error={errors.privacyPolicy && touched.privacyPolicy && errors.privacyPolicy}
                                />
                            </Box>

                            <Box theme={theme} mb="1.25rem">
                                <Button
                                    variation={`primary fullWidth ${isSubmitting ? 'submitting' : ''}`}
                                    type="submit"
                                    disabled={isSubmitting || submitted}>
                                    {isSubmitting ? <InquryLoader /> : t('controls:send-inquiry')}
                                </Button>
                            </Box>
                        </div>
                        {quickTip && (
                            <div className="QuickTip">
                                <div className="QuickTip__head">
                                    <QuestionIcon /> <span className="Title">{t('quick-tip')}</span>
                                    <button type="button" className="CloseButton" onClick={() => closeQuickTip()}>
                                        <CloseIcon />
                                    </button>
                                </div>

                                <div className="QuickTip__content">
                                    <p>{t(`quick-tip-text${type ? `-${type}` : ''}`)}</p>
                                    <p>
                                        <em>{t(`quick-tip-eg${type ? `-${type}` : ''}`)}</em>
                                    </p>
                                </div>
                            </div>
                        )}
                    </form>
                )}
            </Formik>
        </InquiryFormStyled>
    )
}

InquiryForm.propTypes = {
    t: PropTypes.func.isRequired,
    type: PropTypes.string,
    onPage: PropTypes.bool
}

InquiryForm.defaultProps = {
    type: undefined,
    onPage: false
}

export default withTranslation(['common', 'controls'])(withTheme(InquiryForm))
