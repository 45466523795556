import { withTheme } from 'emotion-theming'
import { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import style from './Textarea.style'

const TextareaStyled = styled.div(props => ({ ...style(props) }))

const Textarea = ({ label, name, id, value, rows, error, required, onChange, onBlur, disabled }) => {
    const [active, setActive] = useState(false)

    const handleBlur = e => {
        onBlur(name, true)
        setActive(e.target.value.length > 0)
    }

    return (
        <TextareaStyled error={error}>
            <div className="wrap">
                <label htmlFor={id} className={active ? 'isActive' : ''}>
                    {label}
                </label>
                <textarea
                    id={id}
                    rows={rows}
                    name={name}
                    onChange={onChange}
                    onBlur={e => handleBlur(e)}
                    onFocus={() => setActive(true)}
                    value={value}
                    disabled={disabled}
                    required={required}
                />
            </div>

            {error && (
                <div className="inputError" role="alert">
                    {error}
                </div>
            )}
        </TextareaStyled>
    )
}

Textarea.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    rows: PropTypes.number,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
}

Textarea.defaultProps = {
    value: undefined,
    rows: null,
    error: null,
    required: false,
    disabled: false,
    onChange: () => {},
    onBlur: () => {}
}

export default withTheme(Textarea)
