import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, error }) => ({
    label: {
        display: 'block',
        position: 'relative',
        cursor: 'pointer',
        paddingLeft: 40,
    },

    input: {
        display: 'none'
    },

    '.checkmark': {
        display: 'inline-block',
        position: 'absolute',
        top: 10,
        left: 0,
        width: 20,
        height: 20,
        border: `2px solid ${error ? '#D32929' : theme.colors.goldDark}`,
        borderRadius: 4,

        svg: {
            display: 'none',
            position: 'absolute',
            top: -2,
            left: -1,
            transform: 'scale(0.65, 0.65)',

            path: {
                fill: theme.colors.blueDark
            }
        }
    },

    '& input:checked ~ .checkmark': {
        background: theme.colors.goldDark,

        svg: {
            display: 'block'
        }
    },

    '.inputLabel': {
        display: 'block',
        fontSize: toRem(14),
        lineHeight: '24px',
        paddingTop: 7,
        color: error ? '#D32929' : theme.colors.white,
        a: {
            color: error ? '#D32929' : theme.colors.white,
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    },

    '& .inputError': {
        fontSize: toRem(10),
        color: '#D32929',
        fontStyle: 'italic',
        paddingLeft: toRem(20),
        paddingTop: toRem(5),

        '&:before': {
            content: '"*"'
        }
    }
})

export default style
